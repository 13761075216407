import React, { useEffect, useMemo, useRef} from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Button, Card, CardActionArea, Chip, CircularProgress, FormControl, InputLabel, makeStyles, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { SelectDropdown } from "components/VendorDocumentsUpdate/SelectDropdown";
import { useStyles } from "../../constant";
import { IChip, IGalleryModalProps, IMAGE_OR_VIDEO_TYPE, IMediaCategory, IGalleryImages, ICompanyVideos } from './GalleryModal.interface';
import { GALLERY_MEDIA_CATEGORIES_OPTIONS } from './GalleryModal.constants';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SnackBarNotification from "components/SnackBar/SnackBar"
import { AlertProps, Autocomplete } from '@mui/material';
import { getYoutubeThumbnailFromUrl } from 'shared/handlers/youtube.handler';
import { green } from '@material-ui/core/colors';
import { getUrlFromMediaObject } from './galleryModal.handler';
import { filter } from 'lodash';


export const GalleryModal = (props: IGalleryModalProps) => {
  const { 
    showVideoLoading,
    // plantMachineDetails related props
    plantMachineDetails,
    setPlantMachineDetails, // TODO: remove if not required
    // capabilities related states
    capabilitiesDetails,
    setCapabilitiesDetails,
    // gallery related states
    activeTab, // 0 = Image-Tab; 1 = Vieos-Tab
    setActiveTab,
    showGalleryModal, 
    setShowGalleryModal,
    galleryImageCategory,
    setGalleryImageCategory,
    galleryVideoCategory,
    setGalleryVideoCategory,
    videoUrl,
    setVideoUrl,
    selectedImgOrVideoObj, 
    setSelectedImgOrVideoObj,
    // handlers
    handleOnInputChangeForGallery,
    setIsDataChanged
  } = props;
  const OTHER_CATEGORY = GALLERY_MEDIA_CATEGORIES_OPTIONS?.[2]?.["value"];
  const classes = useStyles();
  const thumbnailItemRef = React.useRef<HTMLDivElement | null>(null);
  const galleryImages = capabilitiesDetails?.gallery || [];
  const companyVideos = capabilitiesDetails?.companyVideo || [];
  const IS_IMAGE_TAB = activeTab === 0;
  const IS_VIDEO_TAB = activeTab === 1;
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [notificationMsg, setNotifificationMsg] = React.useState<{
    msg?: string;
    severity?: AlertProps["severity"] | "";
  }>({
    msg: "",
    severity: "error",
  });

  const [currentCategory, setCurrentCategory] = React.useState("Factory");

  const dragMedia = useRef(0);
  const dragOverMedia = useRef(0);

  /** useEffect to set selected media item */
  useEffect(() => {
    if (IS_IMAGE_TAB && galleryImages?.length && !selectedImgOrVideoObj?.imageName) {
      const filteredImages = filterImageByCategory(
        galleryImages,
        currentCategory
      );
      setSelectedImgOrVideoObj(filteredImages?.[0] || null);
    }
    if (IS_VIDEO_TAB && companyVideos?.length && selectedImgOrVideoObj?.imageName) {
      const filteredVideos = filterVideoByCategory(
        companyVideos,
        currentCategory
      );
      setSelectedImgOrVideoObj(filteredVideos?.[0] || null);
    }
  }, [activeTab, galleryImages, companyVideos]);

  /** useEffect to scroll to selected media item */
  useEffect(() => {
    if (thumbnailItemRef?.current) {
      thumbnailItemRef?.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }
  }, [selectedImgOrVideoObj?.file?.fileUrl, selectedImgOrVideoObj?.videoUrl, galleryImages?.length, companyVideos?.length]);

  const getMediaCategory = (category: string) => {
    const CATEGORY = {
      PRODUCT: GALLERY_MEDIA_CATEGORIES_OPTIONS?.[0]?.value,
      FACTORY: GALLERY_MEDIA_CATEGORIES_OPTIONS?.[1]?.value,
      OTHER: GALLERY_MEDIA_CATEGORIES_OPTIONS?.[2]?.value,
      PROJECTS: GALLERY_MEDIA_CATEGORIES_OPTIONS?.[3]?.value,
    }
    switch (true) {
      case category?.toLowerCase()?.includes(CATEGORY.PRODUCT.toLowerCase()):
        return CATEGORY.PRODUCT;

      case category?.toLowerCase()?.includes(CATEGORY.FACTORY.toLowerCase()):
        return CATEGORY.FACTORY;

      case category?.toLowerCase()?.includes(CATEGORY.OTHER.toLowerCase()):
        return CATEGORY.OTHER;

      case category?.toLowerCase()?.includes(CATEGORY.PROJECTS.toLowerCase()):
        return CATEGORY.PROJECTS;
    
      default:
        return CATEGORY.OTHER;
    }
  }

  const filterImageByCategory = (
    galleryImages: IGalleryImages,
    category: string
  ) => {
    const categoryImage = galleryImages.filter((image) => {
      if (image.category === undefined && category === "Other") {
        image.category = GALLERY_MEDIA_CATEGORIES_OPTIONS?.[2]?.value;
        return true;
      } else {
        return image.category === category;
      }
    });
    return categoryImage;
  };

  const filterVideoByCategory = (
    companyVideos: ICompanyVideos,
    category: string
  ) => {
    const categoryVideo = companyVideos.filter((video) => {
      if (!video.category && category === "Other") {
        video.category = GALLERY_MEDIA_CATEGORIES_OPTIONS?.[2]?.value;
        return true;
      }
      return video.category === category;
    });
    return categoryVideo;
  };

  const handleSortImages = () => {
    const galleryImagesClone = [...galleryImages];
    const draggedMediaIdx = dragMedia.current;
    const draggedOverMediaIdx = dragOverMedia.current;
    const currentMediaArray = filterImageByCategory(
      galleryImages,
      currentCategory
    );
    const finalDraggedMediaIdx = galleryImages.findIndex((item) => item === currentMediaArray[draggedMediaIdx]);
    const finalDraggedOverMediaIdx = galleryImages.findIndex((item) => item === currentMediaArray[draggedOverMediaIdx]);

    galleryImagesClone.splice(finalDraggedOverMediaIdx, 0, galleryImagesClone.splice(finalDraggedMediaIdx, 1)[0]);
    setCapabilitiesDetails({ ...capabilitiesDetails, gallery: galleryImagesClone });
  }

  const handleSortVideos = () => {
    const companyVideosClone = [...companyVideos];
    const draggedMediaIdx = dragMedia.current;
    const draggedOverMediaIdx = dragOverMedia.current;
    const currentMediaArray = filterVideoByCategory(
      companyVideos,
      currentCategory
    );
    const finalDraggedMediaIdx = companyVideos.findIndex((item) => item === currentMediaArray[draggedMediaIdx]);
    const finalDraggedOverMediaIdx = companyVideos.findIndex((item) => item === currentMediaArray[draggedOverMediaIdx]);

    companyVideosClone.splice(finalDraggedOverMediaIdx, 0, companyVideosClone.splice(finalDraggedMediaIdx, 1)[0]);
    setCapabilitiesDetails({ ...capabilitiesDetails, companyVideo: companyVideosClone });
  }

  const handleToggleSnackbarWithMsg = ({msg, flag}: {msg: string, flag: boolean}) => {
    setShowSnackBar(flag);
    setNotifificationMsg({ msg, severity: "error" });
  };

  const handleChangeActivetab = (event: React.ChangeEvent<{}>, newValue: number) => setActiveTab(newValue);

  const handleCloseGalleryModal = () => setShowGalleryModal(false);

  const handleMediaUpload = ({ e, TYPE, currentCategory } : { e: React.ChangeEvent<HTMLInputElement>, TYPE: IMAGE_OR_VIDEO_TYPE, currentCategory: string }) => {
    setIsDataChanged(true)
    if (TYPE === "image") {
      handleOnInputChangeForGallery({ e, TYPE, galleryMediaSource: galleryImages, currentCategory });
    } else if (TYPE === "video") {
      handleOnInputChangeForGallery({ e, TYPE, galleryMediaSource: companyVideos, currentCategory});
    }
  };

  const handleVideoURLUpload = () => {
    setIsDataChanged(true)
    const tempVideoGallery = companyVideos || [];
    if (!videoUrl || videoUrl === "") {
      handleToggleSnackbarWithMsg({ msg: "Please enter video url", flag: true })
      return;
    } 
    /** check if url is valid */
    const validUrl = videoUrl.match(/^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/g);
    if (!validUrl) {
      handleToggleSnackbarWithMsg({ msg: "Please enter valid video url", flag: true })
      return;
    }

    const indexOfNewVideoInExistingCompanyVideos = tempVideoGallery?.findIndex(existingVideoObj => {
      return existingVideoObj?.videoUrl === videoUrl || existingVideoObj?.file?.fileUrl === videoUrl;
    })
    const IS_EXISTING_VIDEO = indexOfNewVideoInExistingCompanyVideos >= 0;
    if (IS_EXISTING_VIDEO) {
      /** only allow only unique URLs to be uploaded */
      handleToggleSnackbarWithMsg({ msg: "Video URL already present", flag: true })
      return;
    }

    tempVideoGallery.push({
      videoUrl: videoUrl,
      name: videoUrl,
      category: getMediaCategory(currentCategory),
    });
    setCapabilitiesDetails({ ...capabilitiesDetails, companyVideo: tempVideoGallery });
    setSelectedImgOrVideoObj(tempVideoGallery?.[tempVideoGallery?.length - 1] || null);
    setVideoUrl("");
  };

  const handleOnDelete = (chip: IChip, TYPE: IMAGE_OR_VIDEO_TYPE) => {
    const CURR_URL = chip?.value || "";

    /** remove same video/image from machines where image was added */
    const removeMediaFromMachineGallery = () => {
      const updatedPlantMachineDetails = plantMachineDetails?.map((prevPlant) => {
        const updatedMachines = prevPlant?.machines?.map((prevMachine) => {
          const updatedGallery = prevMachine?.gallery?.filter((mediaObj) => getUrlFromMediaObject(mediaObj) !== CURR_URL);
          return { ...prevMachine, gallery: updatedGallery };
        });
        return { ...prevPlant, machines: updatedMachines };
      });
      setPlantMachineDetails(updatedPlantMachineDetails);
    };

    if (TYPE === "image") {
      const tempImageGallery = galleryImages?.filter((ele) => ele?.file?.fileUrl !== CURR_URL);
      const currentImages = filterImageByCategory(tempImageGallery, currentCategory);
      setCapabilitiesDetails({ ...capabilitiesDetails, gallery: tempImageGallery });
      setSelectedImgOrVideoObj(currentImages?.[0] || null);
      removeMediaFromMachineGallery();
    } else if (TYPE === "video") {
      const tempVideoGallery = companyVideos?.filter((ele) => {
        if (ele?.file?.fileUrl) return ele?.file?.fileUrl !== CURR_URL;
        return ele?.videoUrl !== CURR_URL;
      });
      const currentVideos = filterVideoByCategory(tempVideoGallery, currentCategory);
      setCapabilitiesDetails({ ...capabilitiesDetails, companyVideo: tempVideoGallery });
      setSelectedImgOrVideoObj(currentVideos?.[0] || null);
      removeMediaFromMachineGallery();
    }
  };

  const handleUpdateStateForSelectedImgOrVideoObj = ({
    TYPE, 
    objWithModifiedKeys,
    URLofDesiredMediaObj
  } : { 
    TYPE: IMAGE_OR_VIDEO_TYPE;
    URLofDesiredMediaObj: string;
    objWithModifiedKeys: typeof selectedImgOrVideoObj;
  }) => {
    if (TYPE === "image") {
      const tempGalleryImages = galleryImages?.map((existingImgObj) => {
        return existingImgObj?.file?.fileUrl === URLofDesiredMediaObj
          ? { ...existingImgObj , ...objWithModifiedKeys }
          : existingImgObj;
      });
      setCapabilitiesDetails({ ...capabilitiesDetails, gallery: tempGalleryImages });
    } else if (TYPE === "video") {
      const tempCompanyVideos = companyVideos?.map((existingVideoObj) => {
        return (
          existingVideoObj?.videoUrl === URLofDesiredMediaObj || existingVideoObj?.file?.fileUrl === URLofDesiredMediaObj
        ) ? { ...existingVideoObj , ...objWithModifiedKeys } : existingVideoObj;
      });
      setCapabilitiesDetails({ ...capabilitiesDetails, companyVideo: tempCompanyVideos });
    }
  };

  const renderBigImageOrVideoContainerWithScrollableThumbnails = ({
    desiredImgOrVideoObj,
    mediaItems,
    mediaType
  } : {
    desiredImgOrVideoObj: typeof selectedImgOrVideoObj,
    mediaItems?: any[],
    mediaType: string
  }) => { 
    let SELECTED_IMG_OR_VIDEO_OBJ = desiredImgOrVideoObj || {};
    const FILE_URL = SELECTED_IMG_OR_VIDEO_OBJ?.file?.fileUrl || "";
    const VIDEO_URL = SELECTED_IMG_OR_VIDEO_OBJ?.videoUrl || "";
    const MEDIA_URL = FILE_URL || VIDEO_URL || "";
    const handleDeleteImgOrVideo = () => {
      setIsDataChanged(true)
      if (SELECTED_IMG_OR_VIDEO_OBJ?.imageName) {
        // for image
        handleOnDelete({
          label: SELECTED_IMG_OR_VIDEO_OBJ?.imageName,
          value: MEDIA_URL,
        }, "image")
      } else {
        // for video
        handleOnDelete({
          label: SELECTED_IMG_OR_VIDEO_OBJ?.name,
          value: MEDIA_URL,
        }, "video")
      }
    };

    const handleChangeCategoryForImgOrVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsDataChanged(true)
      const VALUE = e?.target?.value || OTHER_CATEGORY;
      setSelectedImgOrVideoObj({ ...SELECTED_IMG_OR_VIDEO_OBJ, category: VALUE });
      handleUpdateStateForSelectedImgOrVideoObj({
        TYPE: IS_IMAGE_TAB ? "image" : "video",
        objWithModifiedKeys: { category: VALUE },
        URLofDesiredMediaObj: MEDIA_URL,
      });
    };

    const handleUpdateImageName = {
      onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
        if (SELECTED_IMG_OR_VIDEO_OBJ?.imageName && IS_IMAGE_TAB) {
          // imageName is only present for image
          handleUpdateStateForSelectedImgOrVideoObj({
            TYPE: "image",
            objWithModifiedKeys: { imageName: e?.target?.value || "" },
            URLofDesiredMediaObj: MEDIA_URL,
          });
        }
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        // imageName is only present for image
        if (SELECTED_IMG_OR_VIDEO_OBJ?.imageName && IS_IMAGE_TAB) {
          setSelectedImgOrVideoObj({ ...SELECTED_IMG_OR_VIDEO_OBJ, imageName: e.target.value || "" });
        }
      },
    };

    const handleUpdateDescriptionForImgOrVideo = {
      onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
        handleUpdateStateForSelectedImgOrVideoObj({
          TYPE: IS_IMAGE_TAB ? "image" : "video",
          objWithModifiedKeys: { description: e?.target?.value || "" },
          URLofDesiredMediaObj: MEDIA_URL,
        });
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedImgOrVideoObj({ ...SELECTED_IMG_OR_VIDEO_OBJ, description: e.target.value || "" });
      }
    };

    const handleUpdateCaptionForImgOrVideo = {
      onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
        handleUpdateStateForSelectedImgOrVideoObj({
          TYPE: IS_IMAGE_TAB ? "image" : "video",
          objWithModifiedKeys: { caption: e?.target?.value || "" },
          URLofDesiredMediaObj: MEDIA_URL,
        });
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsDataChanged(true)
        setSelectedImgOrVideoObj({ ...SELECTED_IMG_OR_VIDEO_OBJ, caption: e?.target?.value || "" });
      },
    };

    return (
      <>
        {Object.keys(SELECTED_IMG_OR_VIDEO_OBJ).length !== 0 ? (
          <>
            {/* Big Image & Editable Form Container */}
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              mt={0.5}
              padding={"16px"}
              gridGap={"30px"}
              height={"420px"}
              border={`2px solid ${green[700]}`}
              borderRadius={"12px"}
            >
              {/* Big Image & Video Container */}
              <Box width={IS_IMAGE_TAB ? "50%" : "60%"}>
                <>
                  {SELECTED_IMG_OR_VIDEO_OBJ?.imageName ? (
                    <img
                      width={"300px"}
                      height={"auto"}
                      src={MEDIA_URL}
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <>
                      {/* //check if video is youtube or not */}
                      {MEDIA_URL?.includes("you") ? (
                        <iframe
                          style={{
                            margin: "0",
                            padding: "0",
                            width: "100%",
                            height: "100%",
                            aspectRatio: "16/9",
                            objectFit: "cover",
                            borderRadius: "12px",
                          }}
                          allowFullScreen
                          src={
                            //convert youtube url to embed url and remove values after & symbol
                            //also convert https://youtu.be/yRlXr-pBybs these type of url to embed url
                            //if url is already embed url then it will not change
                            //if youtube.com with watch
                            MEDIA_URL?.includes("youtu.be")
                              ? MEDIA_URL?.replace(
                                  "youtu.be",
                                  "youtube.com/embed"
                                )
                              : MEDIA_URL?.includes("watch")
                              ? MEDIA_URL?.replace("watch?v=", "embed/")?.split(
                                  "&"
                                )?.[0]
                              : MEDIA_URL
                          }
                        />
                      ) : (
                        <video
                          width={"100%"}
                          height={"100%"}
                          poster={"/images/video-thumbnail.png"}
                          src={MEDIA_URL}
                          controls
                          style={{
                            aspectRatio: "16/9",
                            objectFit: "cover",
                            borderRadius: "12px",
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              </Box>
              {/* Editable Form Input Fields */}
              <Box
                display={"flex"}
                flexDirection={"column"}
                gridGap={"8px"}
                // width={"100%"}
                width={"50%"}
              >
                {/* View & Delete Button */}
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gridGap={"20px"}
                  mb={1}
                >
                  {/* View Button */}
                  <Button
                    variant="outlined"
                    color={"primary"}
                    onClick={() => window.open(MEDIA_URL, "_blank")}
                  >
                    View
                  </Button>
                  {/* Delete Button */}
                  <Button
                    variant="outlined"
                    color={"secondary"}
                    onClick={handleDeleteImgOrVideo}
                  >
                    Delete
                  </Button>
                  {/* Image Name */}
                  <Typography>
                    Name:{" "}
                    {SELECTED_IMG_OR_VIDEO_OBJ?.imageName ||
                      SELECTED_IMG_OR_VIDEO_OBJ?.name}
                  </Typography>
                </Box>
                {/* Caption */}
                <TextField
                  multiline
                  maxRows={3}
                  size={"small"}
                  label="Caption"
                  variant="outlined"
                  value={SELECTED_IMG_OR_VIDEO_OBJ?.caption || ""}
                  onBlur={handleUpdateCaptionForImgOrVideo.onBlur}
                  defaultValue={selectedImgOrVideoObj?.caption || ""}
                  onChange={handleUpdateCaptionForImgOrVideo.onChange}
                />

                {/* Category */}
                <SelectDropdown
                  label="Category"
                  style={{ width: "200px" }}
                  options={GALLERY_MEDIA_CATEGORIES_OPTIONS as any}
                  handleOnChange={handleChangeCategoryForImgOrVideo}
                  value={getMediaCategory(SELECTED_IMG_OR_VIDEO_OBJ?.category)}
                />

                {/* Image Name */}
                {/* {SELECTED_IMG_OR_VIDEO_OBJ?.imageName && (
              <TextField
                label="Image Name"
                variant="outlined"
                value={SELECTED_IMG_OR_VIDEO_OBJ?.imageName || ""}
                defaultValue={SELECTED_IMG_OR_VIDEO_OBJ?.imageName || ""}
                onChange={handleUpdateImageName.onChange}
                onBlur={handleUpdateImageName.onBlur}
              />
            )} */}
                {/* Desription */}
                {/* <TextField
              multiline
              maxRows={3}
              label="Description"
              variant="outlined"
              value={SELECTED_IMG_OR_VIDEO_OBJ?.description || ""}
              defaultValue={SELECTED_IMG_OR_VIDEO_OBJ?.description || ""}
              onBlur={handleUpdateDescriptionForImgOrVideo.onBlur}
              onChange={handleUpdateDescriptionForImgOrVideo.onChange}
            /> */}
              </Box>
            </Box>

            {/* Scrollable Thumbnails */}
            <Box
              display={"flex"}
              alignItems={"center"}
              gridGap={"16px"}
              overflow={"auto"}
              className={(classes as any).galleryScroll}
              bgcolor={"#dfddea"}
              pt={0.75}
              pb={0.2}
              mt={1}
            >
              {mediaItems?.map((mediaItemObj, index) => {
                const IS_CURRENT_IETM_SELECTED =
                  MEDIA_URL === mediaItemObj?.file?.fileUrl ||
                  MEDIA_URL === mediaItemObj?.videoUrl;
                const CURR_MEDIA_URL =
                  mediaItemObj?.file?.fileUrl || mediaItemObj?.videoUrl || "";
                const IS_YOUTUBE_URL = CURR_MEDIA_URL?.includes("you");
                const VIDEO_THUMBNAIL_IMG_SRC = IS_YOUTUBE_URL
                  ? getYoutubeThumbnailFromUrl(CURR_MEDIA_URL, 0)
                  : "/images/video-thumbnail.png";

                return (
                  <div ref={IS_CURRENT_IETM_SELECTED ? thumbnailItemRef : null}>
                    <Box
                      key={CURR_MEDIA_URL}
                      display={"flex"}
                      borderRadius={"12px"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectedImgOrVideoObj(mediaItemObj)}
                      border={
                        IS_CURRENT_IETM_SELECTED
                          ? `3px solid ${green[400]}`
                          : "1px solid black"
                      }
                    >
                      <>
                        {mediaItemObj?.imageName ? (
                          <img
                            height={"70px"}
                            width={"150px"}
                            src={CURR_MEDIA_URL}
                            style={{ objectFit: "cover", borderRadius: "12px" }}
                            alt={
                              mediaItemObj?.imageName ||
                              mediaItemObj?.file?.fileName ||
                              `media-${index}`
                            }
                            draggable
                            onDragStart = {() =>(dragMedia.current = index)}
                            onDragEnter = {() =>(dragOverMedia.current = index)}
                            onDragEnd = {handleSortImages}
                            onDragOver = {(e) => e.preventDefault()}
                          />
                        ) : (
                          <video
                            height={"70px"}
                            width={"auto"}
                            poster={VIDEO_THUMBNAIL_IMG_SRC}
                            style={{
                              aspectRatio: "16/9",
                              padding: "0",
                              margin: "0",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                            draggable
                            onDragStart = {() =>(dragMedia.current = index)}
                            onDragEnter = {() =>(dragOverMedia.current = index)}
                            onDragEnd = {handleSortVideos}
                            onDragOver = {(e) => e.preventDefault()}
                          />
                        )}
                      </>
                    </Box>
                  </div>
                );
              })}
            </Box>
          </>
        ) : (
          <Typography variant="h2">{mediaType!=='Image'? "No Videos Present": "No Images Present"}</Typography>
        )}
      </>
    );
  };

  const handleImageCategoryButtonClick = (category: string) => {
    setCurrentCategory(category);
    const selectedMedia = filterImageByCategory(galleryImages, category);
    setSelectedImgOrVideoObj(selectedMedia[0]);
  };

  const handleVideoCategoryButtonClick = (category: string) => {
    setCurrentCategory(category);
    const selectedMedia = filterVideoByCategory(companyVideos, category);
    setSelectedImgOrVideoObj(selectedMedia[0]);
  };

  return (
    <>
      <Modal
        closeAfterTransition
        open={showGalleryModal}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        onClose={handleCloseGalleryModal}
        className={(classes as any).galleryModalStyle}
      >
        <Fade in={showGalleryModal}>
          <Box className={(classes as any).galleryModalInnerContainer}>
            {/* Tab Bar */}
            <Box
              display={"flex"}
              alignItems={"center"}
              style={{ height: "20px" }}
              justifyContent={"space-between"}
            >
              <Tabs
                value={activeTab}
                textColor="primary"
                indicatorColor="primary"
                onChange={handleChangeActivetab}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Tab label={`Images (${galleryImages?.length})`} style={{ padding: "2px 12px" }} />
                <Tab label={`Videos (${companyVideos?.length})`} style={{ padding: "2px 12px" }} />
                {/* Disclaimer text */}
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "rgba(108, 117, 125, 1", 
                    fontStyle: "italic", 
                    paddingTop: "12px",
                    marginLeft: "12px",
                  }}
                >
                  Save changes by clicking <b>Save</b> button on page level
                </Typography>
              </Tabs>
              <Button onClick={handleCloseGalleryModal}>
                <CloseRoundedIcon />
              </Button>
            </Box>       
            {/* Image Gallery - Tab Content */}
            <Box
              mt={1}
              flexDirection={"column"}
              position={"relative"}
              height={"90%"}
              display={activeTab === 0 ? "flex" : "none"}
            >
              <Box mt={2}>
                <Button
                  variant="outlined"
                  color={"primary"}
                  style={
                    currentCategory ===
                    GALLERY_MEDIA_CATEGORIES_OPTIONS?.[1]?.value
                      ? {
                          backgroundColor: "#C4D3FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                      : {
                          backgroundColor: "#F4F6FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                  }
                  onClick={() =>
                    handleImageCategoryButtonClick(
                      GALLERY_MEDIA_CATEGORIES_OPTIONS?.[1]?.value
                    )
                  }
                >
                  factory
                </Button>
                <Button
                  variant="outlined"
                  color={"primary"}
                  style={
                    currentCategory ===
                    GALLERY_MEDIA_CATEGORIES_OPTIONS?.[0]?.value
                      ? {
                          backgroundColor: "#C4D3FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                      : {
                          backgroundColor: "#F4F6FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                  }
                  onClick={() =>
                    handleImageCategoryButtonClick(
                      GALLERY_MEDIA_CATEGORIES_OPTIONS?.[0]?.value
                    )
                  }
                >
                  Product
                </Button>
                <Button
                  variant="outlined"
                  color={"primary"}
                  style={
                    currentCategory ===
                    GALLERY_MEDIA_CATEGORIES_OPTIONS?.[3]?.value
                      ? {
                          backgroundColor: "#C4D3FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                      : {
                          backgroundColor: "#F4F6FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                  }
                  onClick={() =>
                    handleImageCategoryButtonClick(
                      GALLERY_MEDIA_CATEGORIES_OPTIONS?.[3]?.value
                    )
                  }
                >
                  Projects
                </Button>
                <Button
                  variant="outlined"
                  color={"primary"}
                  style={
                    currentCategory ==
                    GALLERY_MEDIA_CATEGORIES_OPTIONS?.[2]?.value
                      ? {
                          backgroundColor: "#C4D3FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                      : {
                          backgroundColor: "#F4F6FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                  }
                  onClick={() =>
                    handleImageCategoryButtonClick(
                      GALLERY_MEDIA_CATEGORIES_OPTIONS?.[2]?.value
                    )
                  }
                >
                  Other
                </Button>
                {/* <SelectDropdown
                  label="Category"
                  style={{ width: "200px" }}
                  value={getMediaCategory(galleryImageCategory)}
                  options={GALLERY_MEDIA_CATEGORIES_OPTIONS as any}
                  handleOnChange={(e) => setGalleryImageCategory(e.target.value)}
                /> */}
                {/* Image Upload Button */}
                <>
                  <input
                    multiple
                    type="file"
                    accept="image/*"
                    id="image-gallery-upload"
                    style={{ display: "none" }}
                    onChange={(e) =>
                      handleMediaUpload({ e, TYPE: "image", currentCategory })
                    }
                  />
                  <label htmlFor="image-gallery-upload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className="ml-3"
                      
                      style={{ height: "40px", position: "absolute", top: "12px", right: "0px"}}
                    >
                      Upload
                    </Button>
                  </label>
                </>
                <>
                  {galleryImages?.length > 0 ? (
                    renderBigImageOrVideoContainerWithScrollableThumbnails({
                      desiredImgOrVideoObj: selectedImgOrVideoObj,
                      mediaItems: filterImageByCategory(
                        galleryImages,
                        currentCategory
                      ),
                      mediaType: "Image",
                    })
                  ) : (
                    <Typography variant="h2">No Images Present</Typography>
                  )}
                </>
              </Box>
            </Box>

            {/* Video Gallery - Tab Content */}
            <Box
              display={activeTab === 1 ? "flex" : "none"}
              flexDirection={"column"}
              style={{position: "relative", marginTop: "0.5rem"}}

            >
              <Box display={"flex"} alignItems={"center"} mt={2}>
                {/* <SelectDropdown
                  label="Category"
                  style={{ width: "200px" }}
                  value={getMediaCategory(galleryVideoCategory)}
                  options={GALLERY_MEDIA_CATEGORIES_OPTIONS as any}
                  handleOnChange={(e) => setGalleryVideoCategory(e.target.value)}
                /> */}
                {/* Video - Upload Button */}
                <Button
                  variant="outlined"
                  style={
                    currentCategory ===
                    GALLERY_MEDIA_CATEGORIES_OPTIONS?.[1]?.value
                      ? {
                          backgroundColor: "#C4D3FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                      : {
                          backgroundColor: "#F4F6FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                  }
                  color={"primary"}
                  onClick={() =>
                    handleVideoCategoryButtonClick(
                      GALLERY_MEDIA_CATEGORIES_OPTIONS?.[1]?.value
                    )
                  }
                >
                  factory
                </Button>
                <Button
                  variant="outlined"
                  color={"primary"}
                  style={
                    currentCategory ===
                    GALLERY_MEDIA_CATEGORIES_OPTIONS?.[0]?.value
                      ? {
                          backgroundColor: "#C4D3FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                      : {
                          backgroundColor: "#F4F6FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                  }
                  onClick={() =>
                    handleVideoCategoryButtonClick(
                      GALLERY_MEDIA_CATEGORIES_OPTIONS?.[0]?.value
                    )
                  }
                >
                  Product
                </Button>
                <Button
                  variant="outlined"
                  color={"primary"}
                  style={
                    currentCategory ===
                    GALLERY_MEDIA_CATEGORIES_OPTIONS?.[3]?.value
                      ? {
                          backgroundColor: "#C4D3FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                      : {
                          backgroundColor: "#F4F6FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                  }
                  onClick={() =>
                    handleVideoCategoryButtonClick(
                      GALLERY_MEDIA_CATEGORIES_OPTIONS?.[3]?.value
                    )
                  }
                >
                  Projects
                </Button>
                <Button
                  variant="outlined"
                  color={"primary"}
                  style={
                    currentCategory ===
                    GALLERY_MEDIA_CATEGORIES_OPTIONS?.[2]?.value
                      ? {
                          backgroundColor: "#C4D3FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                      : {
                          backgroundColor: "#F4F6FF",
                          color: "#376BFC",
                          marginRight: "1rem",
                          borderRadius: "50px",
                        }
                  }
                  onClick={() =>
                    handleVideoCategoryButtonClick(
                      GALLERY_MEDIA_CATEGORIES_OPTIONS?.[2]?.value
                    )
                  }
                >
                  Other
                </Button>
                <div style={{ display: "flex", position: "absolute", top: "12px", right: "0px"}}>
                  <input
                    multiple
                    type="file"
                    accept=".mp4"
                    id="video-gallery-upload"
                    style={{ display: "none"}}
                    onChange={(e) =>
                      handleMediaUpload({ e, TYPE: "video", currentCategory })
                    }
                  />
                  <label htmlFor="video-gallery-upload">
                    <Button
                      color="primary"
                      component="span"
                      variant="contained"
                      className="ml-3"
                      style={{ height: "40px"}}
                    >
                      Upload
                    </Button>
                  </label>
                  <Typography
                    variant="h6"
                    className="mx-4"
                    style={{ color: "rgba(108, 117, 125, 1" }}
                  >
                    Or
                  </Typography>
                  <TextField
                    size="small"
                    value={videoUrl}
                    variant="outlined"
                    id="outlined-basic"
                    style={{ width: "300px" }}
                    label="Type and Press Add"
                    onChange={(e) => {
                      setIsDataChanged(true)
                      setVideoUrl(e.target.value)
                    }}
                  />
                  {/* VIdeo URL Upload Button */}
                  <Button
                    color="primary"
                    className="ml-3"
                    variant="contained"
                    style={{ height: "40px" }}
                    onClick={handleVideoURLUpload}
                  >
                    Add
                  </Button>
                </div>
              </Box>
              <>
                {companyVideos?.length > 0 ? (
                  renderBigImageOrVideoContainerWithScrollableThumbnails({
                    desiredImgOrVideoObj: selectedImgOrVideoObj,
                    mediaItems: filterVideoByCategory(
                      companyVideos,
                      currentCategory
                    ),
                    mediaType: "Video",
                  })
                ) : (
                  <Typography variant="h2">No Videos Present</Typography>
                )}
              </>
              {showVideoLoading && <CircularProgress color="inherit" />}
            </Box>
          </Box>
        </Fade>
      </Modal>
      <SnackBarNotification
        severity={notificationMsg.severity}
        message={notificationMsg.msg}
        openNotification={showSnackBar}
        closeSnackbar={() => {
          handleToggleSnackbarWithMsg({ msg: "", flag: false });
        }}
      />
    </>
  );
}
