import DateFnsUtils from "@date-io/date-fns";
import { Backdrop, Button, CircularProgress, Grid, InputLabel, TextField } from "@material-ui/core";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import * as moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./VendorsCompanyInfoUpdate.scss";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../NoAccess/NoAccess";
import * as _ from "lodash";
import FileStorage from "../FileStorage";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { dropDownData } from "../../constants/updateVendorConstants";
import { useStyles } from "./vendorCompanyInfoUpdate.styles";
import { getIndianStatesOrCities } from "shared/helpers/getIndianStatesOrCities";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";
import { usePrompt } from "components/Modal/usePrompt";
import { checkUserHasOnlyVendorViewRole } from "shared/helpers/checkHasAccess";

const VendorsCompanyInfoUpdate = () => {
  const classes = useStyles();
  const [loader, enableLoader] = React.useState(false);
  const [reloadUpdate, setReloadUpdate] = React.useState(false);
  const [companyInfo, setCompanyInfo] = React.useState({});
  const [yoc, setYoc] = React.useState(new Date());
  const { gstn } = useParams();
  const [dropDownLocations, setDropDownLocations] = React.useState({ cities: [], states: [] });
  const [dropDownLocationValue, setDropDownLocationValue] = React.useState({
    city: { selectedValue: "", defaultValue: "" },
    state: { selectedValue: "", defaultValue: "" },
  });
  const prompt = usePrompt();

  const updateVendor = (gstn, companyInfo) => {
    updateCompanyInfo(gstn, companyInfo);
    backFillKeyContacts(companyInfo.website);
  };

  const backFillKeyContacts = async (website) =>{
    const PAYLOAD = {
      domain_name: website,
      gstn: gstn
    };
    const result = await axios.post(process.env.REACT_APP_API_URL + "/v1/vendor-profile/backfill-key-contacts", PAYLOAD, {
      headers: {
        "Content-Type": "application/json"
      },
    });
  }
  const access = checkUserHasOnlyVendorViewRole()
  const [hasAccess, setHasAccess] = React.useState(access);

  const updateCompanyInfo = async (updateId, payload) => {
    const PAYLOAD = {
      ...payload,
      fulladdress: {
        ...payload?.fulladdress,
        city: dropDownLocationValue.city.selectedValue || payload?.fulladdress?.city,
        state: dropDownLocationValue.state.selectedValue || payload?.fulladdress?.state,
      },
    };
    const result = await axios.put(process.env.REACT_APP_BASE_URL + "/company-info/" + updateId, PAYLOAD, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    });
    if (result.data.status === "SUCCESS") {
      setReloadUpdate(true);
      leadSquaredTrigger(updateId);
    } else {
      setReloadUpdate(true);
    }
  };

  const uploadFile = async (e, type) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const result = await axios.post(process.env.REACT_APP_BASE_URL + "/file-storage/upload", data, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    });
    if (result.data.status === "SUCCESS") {
      createPermanentLink(result.data.data, e.target.files[0].name, type);
    } else {
    }
  };

  const createPermanentLink = async (payload, fileName, type) => {
    const result = await axios.post(process.env.REACT_APP_BASE_URL + "/file-storage/permanent", payload, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    });
    if (result?.data?.status === "SUCCESS") {
      const file = result.data.data;
      let companyInfoCopy = _.cloneDeep(companyInfo);
      companyInfoCopy[type] = file;
      setCompanyInfo(companyInfoCopy);
    } else {
    }
  };

  const setStartExperienceDates = (date, originalData) => {
    setYoc(moment(date).format("YYYY-MM-DD"));
    setCompanyInfo({ ...originalData, yoc: date, ["yocModified"]: true });
  };

  useEffect(async () => {
    enableLoader(true);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/company-info/" + gstn, {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      })
      .then((result) => {
        if (result.data.status == "SUCCESS") {
          const respData = result?.data?.data;
          setCompanyInfo(respData);
          setDropDownLocationValue((prev) => ({
            ...prev,
            city: { selectedValue: "", defaultValue: respData?.fulladdress?.city?.trim() || "" },
            state: { selectedValue: "", defaultValue: respData?.fulladdress?.state?.trim() || "" },
          }));

          const defaultState = respData?.fulladdress?.state?.trim();
          // get cities from default state
          if (defaultState) {
            getIndianStatesOrCities(defaultState).then((cities) => {
              setDropDownLocations((prev) => ({ ...prev, cities: cities || [] }));
            });
          }
          // get indian states
          getIndianStatesOrCities().then((states) => {
            setDropDownLocations((prev) => ({ ...prev, states: states || [] }));
          });

          setReloadUpdate(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setHasAccess(false);
        }
      });
    enableLoader(false);
  }, [reloadUpdate]);

  /** get cities from selected State */
  useEffect(() => {
    const selectedState = dropDownLocationValue.state.selectedValue;
    if (selectedState) {
      enableLoader(true);
      getIndianStatesOrCities(selectedState).then((cities) => {
        setDropDownLocations((prev) => ({ ...prev, cities }));
      });
      enableLoader(false);
    }
  }, [dropDownLocationValue.state.selectedValue]);

  const removeDocuments = (e, element, type) => {
    let companyInfoCopy = _.cloneDeep(companyInfo);
    const currentIndex = companyInfoCopy?.[type]?.findIndex((entry) => entry.file?.fileUrl === element.file?.fileUrl);
    if (currentIndex !== -1) {
      companyInfoCopy?.[type]?.splice(currentIndex, 1);
      prompt.setIsDataChanged(true);
      setCompanyInfo(companyInfoCopy);
    }
  };

  const handleFileChange = (file, fileName, type) => {
    let companyInfoCopy = _.cloneDeep(companyInfo);
    const fileObject = {
      name: fileName,
      file: file,
    };
    if (companyInfoCopy[type]) {
      companyInfoCopy[type].push(fileObject);
    } else {
      companyInfoCopy[type] = [fileObject];
    }

    setCompanyInfo(companyInfoCopy);
  };

  const handleOnChangeAddress = (e) => {
    prompt.setIsDataChanged(true);
    setCompanyInfo((prev) => ({ ...prev, fulladdress: { ...prev?.fulladdress, address: e.target.value } }));
  };

  /**
   * @param {import("react").ChangeEvent} e
   * @param {"city" | "state"} type
   */
  const handleOnChangeLocation = (e, type) => {
    setDropDownLocationValue((prev) => {
      if (type === "state") {
        return {
          city: { defaultValue: "", selectedValue: "" }, // empty city when new state is selected
          state: { defaultValue: "", selectedValue: e?.target?.value },
        };
      }
      return { ...prev, city: { defaultValue: "", selectedValue: e?.target?.value } };
    });
  };

  return hasAccess ? (
    <>
      <div className="container-fluid scrollable-container">
        <div className={classes.peopleInfoContainer}>
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="row mt-4">
            {/* CompanyName and GSTN */}
            <div className="col">
              <div className="row">
                <InputLabel className={classes.subHeader}>Company Name</InputLabel>
                <TextField
                  id="1"
                  value={companyInfo.companyName || ""}
                  variant="outlined"
                  onChange={(e) => {
                    prompt.setIsDataChanged(true);
                    setCompanyInfo((data) => ({ ...data, companyName: e.target.value }));
                  }}
                  fullWidth
                />
              </div>
              <div className="row mt-4">
                <InputLabel className={classes.subHeader}>GSTN</InputLabel>
                <TextField id="gstn" disabled={true} value={companyInfo.gstn || ""} variant="outlined" fullWidth />
              </div>
            </div>
            {/* YOC and Turn over slab */}
            <div className="col">
              <div className="row">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="col">
                    <InputLabel className={classes.subHeader}>Year of Incorporation</InputLabel>
                    <KeyboardDatePicker
                      helperText={""}
                      error={false}
                      margin="normal"
                      id="projStartDate"
                      format="dd-MM-yyyy"
                      fullWidth
                      value={companyInfo?.yoc ? moment(companyInfo?.yoc, "YYYY-MM-DD").utc(true).toDate() : ""}
                      onChange={(date) => setStartExperienceDates(date, companyInfo)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>

              <div className="row mt-4">
                <div className="col">
                  <InputLabel className={classes.subHeader}>Annual Turn Over Slab</InputLabel>
                  <Select
                    id="annual-turn-over"
                    value={companyInfo?.turnOverSlab ? companyInfo?.turnOverSlab?.slice(9)?.trim() : ""}
                    label={""}
                    onChange={(e) => {
                      prompt.setIsDataChanged(true);
                      setCompanyInfo((data) => ({
                        ...data,
                        turnOverSlab: "Slab: Rs." + e.target.value,
                        ["turnOverSlabModified"]: true,
                      }));
                    }}
                    className="full-width"
                  >
                    {dropDownData.turnOverSlab.map((element) => {
                      const value = element.value;
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                  </Select>
                  {/* <TextField
                    disabled={true}
                    id="turnOverSlab"
                    value={companyInfo.turnOverSlab || ""}
                    variant="outlined"
                    fullWidth
                  /> */}
                </div>
              </div>
            </div>
            {/* Company Logo */}
            <div className="col">
              <InputLabel className={classes.subHeader}>Company Logo</InputLabel>
              <Grid className="logo-comp" item xs>
                {/* <input type="file" className="logo-input" accept="image/jpeg,image/png,application/pdf" onChange={(e) => console.log(e)} /> */}
                <Button
                  variant="contained"
                  component="label"
                  className={classes.uploadButton}
                  startIcon={<FileUploadIcon />}
                >
                  Update Logo
                  <input
                    type="file"
                    hidden
                    accept="image/jpeg,image/png,application/pdf"
                    onChange={(e) => {
                      prompt.setIsDataChanged(true);
                      uploadFile(e, "companyLogo")
                    }}
                  />
                </Button>
                {companyInfo?.companyLogo ? (
                  <img className="logo-image" src={companyInfo?.companyLogo?.fileUrl} />
                ) : null}
              </Grid>
            </div>
          </div>

          {/* Website and Brochure */}
          <div className="row mt-4">
            <div className="col remove-padding-left">
              <InputLabel className={classes.subHeader}>Website</InputLabel>
              <TextField
                id="website"
                value={companyInfo.website || ""}
                variant="outlined"
                onChange={(e) => {
                  prompt.setIsDataChanged(true);
                  setCompanyInfo((data) => ({ ...data, website: e.target.value }));
                }}
                fullWidth
              />
            </div>
            <div className="col">
              <div className="row">
                <InputLabel className={classes.subHeader}>Brochures(multiple)</InputLabel>
              </div>
              <FileStorage
                data={companyInfo?.companyBrochure}
                setLocalState={(file, fileName) => {
                  prompt.setIsDataChanged(true);
                  handleFileChange(file, fileName, "companyBrochure")
                }}
                removeDocuments={(e, element) => removeDocuments(e, element, "companyBrochure")}
              />
            </div>
          </div>
          <hr className={classes.splitLine}></hr>

          <div className="row mt-4">
            {/* State */}
            <div className="col remove-padding-left relative">
              <InputLabel className={classes.subHeader}>State</InputLabel>
              {!loader && (
                <Select
                  label=""
                  fullWidth
                  value={dropDownLocationValue.state.defaultValue || dropDownLocationValue.state.selectedValue || 0}
                  onChange={(e) => {
                    prompt.setIsDataChanged(true);
                    handleOnChangeLocation(e, "state")
                  }}
                >
                  <MenuItem value={0}>
                    <em>Select State</em>
                  </MenuItem>
                  <MenuItem value={dropDownLocationValue.state.defaultValue || ""}>
                    <em>{dropDownLocationValue.state.defaultValue}</em>
                  </MenuItem>
                  {dropDownLocations?.states?.map((state) => (
                    <MenuItem value={state?.name}>{state?.name}</MenuItem>
                  ))}
                </Select>
              )}
            </div>
            {/* City */}
            <div className="col">
              <InputLabel className={classes.subHeader}>City</InputLabel>
              <Select
                value={dropDownLocationValue.city.defaultValue || dropDownLocationValue.city.selectedValue || 0}
                onChange={(e) => {
                  prompt.setIsDataChanged(true);
                  handleOnChangeLocation(e, "city")
                }}
                fullWidth
                label=""
              >
                <MenuItem value={0}>
                  <em>Select City</em>
                </MenuItem>
                <MenuItem value={dropDownLocationValue.city.defaultValue || ""}>
                  <em>{dropDownLocationValue.city.defaultValue}</em>
                </MenuItem>
                {dropDownLocations?.cities?.map((cityName) => (
                  <MenuItem value={cityName}>{cityName}</MenuItem>
                ))}
              </Select>
            </div>
          </div>
          {/* Address */}
          <div className="row mt-4">
            <div className="col remove-padding-left">
              <InputLabel className={classes.subHeader}>Address</InputLabel>
              <TextField
                onChange={handleOnChangeAddress}
                id="fulladdress"
                value={companyInfo?.fulladdress?.address || ""}
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
          {/* Case Studies */}
          <div className="row mt-4">
            <div className="col remove-padding-left">
              <InputLabel className={classes.subHeader}>Case Studies</InputLabel>
              <TextField
                id="caseStudies"
                value={companyInfo.caseStudies || ""}
                variant="outlined"
                onChange={(e) => {
                  prompt.setIsDataChanged(true);
                  setCompanyInfo((data) => ({ ...data, caseStudies: e.target.value }));
                }}
                fullWidth
              />
            </div>
            {/* Address Proof */}
            <div className="col ml-4">
              <div className="row">
                <InputLabel className={classes.subHeader}>Address Proof</InputLabel>
              </div>
              <FileStorage
                data={companyInfo?.addressProof}
                setLocalState={(file, fileName) => {
                  prompt.setIsDataChanged(true);
                  handleFileChange(file, fileName, "addressProof")
                }}
                removeDocuments={(e, element) => removeDocuments(e, element, "addressProof")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="save-button-container">
        <Button
          variant="contained"
          color="primary"
          className="save-button"
          startIcon={<CloudUploadIcon />}
          onClick={() => {
            prompt.setIsDataChanged(false)
            updateVendor(gstn, companyInfo)
          }}
        >
          Save
        </Button>
      </div>
    </>
  ) : (
    <NoAccess></NoAccess>
  );
};

export default VendorsCompanyInfoUpdate;
