export interface IVendorDocument {
  source: string;
  sourceValue?: string;
  sourceFile: {
    name: string;
    file: { fileName: string; fileSize: number; fileUrl: string };
  };
  destination?: string;
  destinationValue?: string;
}

export interface IVendorDocsResp {
  documents: IVendorDocument[];
  optionsWithValues: { [key in string]: string[] };
}

export type IFileDoc = {
  name?: string;
  documentFile?: Partial<IVendorDocument["sourceFile"]["file"]>;
  clientName?: string;
}

export enum REPORTS_KEY_NAMES_ENUM {
  VCB = "VCB", 
  financialAnalysis = "financialAnalysis", 
  VIR = "VIR", 
  TPI = "TPI", 
  VCR = "VCR", 
  pastJobFeedback = "pastJobFeedback",
  others = "others",
};

export enum OTHER_DOCUMENTS_KEY_NAMES_ENUM{
  gstn = "gstn",
  document = "document",
  oemDocuments = "oemDocuments",
  pan = "pan",
  incorpCertif = "incorpCertif",
  financialStatements = "financialStatements",
  itr = "itr",
  gstr3b = "gstr3b",
  netProfitStatements = "netProfitStatements",
  manPowerSplit = "manPowerSplit",
  others = "others",
  MSME = "MSME"
};

export enum OTHER_SENSITIVE_DOCUMENTS_KEY_NAMES_ENUM {
  nda = "nda",
};

export const OTHER_DOCUMENTS_KEY_NAMES_ENUM_EXTENDED = {
  ...OTHER_DOCUMENTS_KEY_NAMES_ENUM,
  ...OTHER_SENSITIVE_DOCUMENTS_KEY_NAMES_ENUM
}

export type IOtherSensitiveDocumentsKeyNamesType = keyof typeof OTHER_SENSITIVE_DOCUMENTS_KEY_NAMES_ENUM;

export type IOtherDocumentsKeyNamesType = keyof typeof OTHER_DOCUMENTS_KEY_NAMES_ENUM;

export type IOtherDocumentsKeyNamesTypeWithoutGstn = Exclude<IOtherDocumentsKeyNamesType, "gstn">;

export type IReportDocumentsKeyNamesType = keyof typeof REPORTS_KEY_NAMES_ENUM

export type IOtherDocumentsData = {
  [key in keyof typeof OTHER_DOCUMENTS_KEY_NAMES_ENUM_EXTENDED]?: Array<IFileDoc>;
};

export const REPORTS_KEY_NAMES_ENUM_NEW = {
  ...REPORTS_KEY_NAMES_ENUM
}

export type IReportDocumentsData = {
  [key in keyof typeof REPORTS_KEY_NAMES_ENUM_NEW]?: Array<IFileDoc>;
};

export type IReportPayload = {
  gstn: string
  reports: IReportDocumentsData
}

export type IDocumentsData=IReportDocumentsData & IOtherDocumentsData;
export type IDocumentsKeyNamesTypeWithoutGstn=keyof IReportDocumentsData | keyof IOtherDocumentsData;;
export type IDocumentsKeyNamesType=keyof IReportDocumentsData | keyof IOtherDocumentsData;
export type DOCUMENTS_KEY_NAMES_ENUM=typeof REPORTS_KEY_NAMES_ENUM | typeof OTHER_DOCUMENTS_KEY_NAMES_ENUM;
export interface IVendorDocumentAccordionProps {
    gstn: string;
    defaultExpanded: boolean;
    isViewOpen: boolean;
    title: string;
    docType: IDocumentsKeyNamesType;
    handleChangeDocType: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isDocUploading: boolean;
    DocumentsData: IDocumentsData;
    setIsDocUploading: React.Dispatch<React.SetStateAction<boolean>>;
    DOCUMENTS_UPLOAD_OPTIONS: Array<{ label: string, value: string }>;
    DOCUMENTS_KEY_NAMES_ENUM: DOCUMENTS_KEY_NAMES_ENUM;
    hiddenInputRef: React.RefObject<HTMLInputElement>;
    postDoc: (args: IDocumentsData) => void;
    setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
    setNotificationMessage: React.Dispatch<React.SetStateAction<string>>;
}
